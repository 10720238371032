'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var focalPoint = require('focal-point');
var lazyImage = require('lazy-image');

var mobileSlideshow = require('mobile-core-slideshow');
var mobileCalendar = require('mobile-core-calendar');
var mobileForm = require('mobile-core-form');
var mobileMenu = require('mobile-core-menu');
var formWidget = require('mobile-core-form-widget');

var dom = {
	document: $(document)
};

var globals = window.mobile.globals;

var getPageId = function(){
	return $.cookie('pageId');
};

var getUrlParam = function(name) {

	return decodeURI(
		(RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [, null])[1]
	);

};

var setPageId = function(){
	$.cookie('pageId', getUrlParam('page'));
};

var showLoader = function(){
	$('.ui-loader').show();
};

var hideLoader = function(){
	$('.ui-loader').hide();
};

var processPageLoad = function(){

	var $page = $('.ui-page-active');
	var isGalleryPage = $page.hasClass('gallery');
	var isImgPage = $page.hasClass('imgFull');
	var isCalendarPage = $page.hasClass('calendar');
	var isFormWidgetPage = $page.hasClass('form');

	mobileMenu.updateSelectedMenuItem();

	setPageId();

	console.log('isGalleryPage: ' + isGalleryPage);

	if (isGalleryPage) {

		mobileSlideshow.init();

		if(mobileSlideshow.hasImages()){
			mobileSlideshow.setupGalleryView();
		}

		else {
			$('.ui-page-active').addClass('no-img');
		}

	} else if (isImgPage) {

		mobileSlideshow.init();

		mobileSlideshow.updateImg();

	} else if (isCalendarPage) {

		mobileCalendar.init();

	}

	if (isFormWidgetPage) {

		mobileForm.destroy();
		formWidget.init();

	} else {

		formWidget.destroy();
		mobileForm.init();

	}

	if(window.itemImageInit){

		window.itemImageInit();

	}

	focalPoint.init({
		target: '.ui-page-active .focal-point',
		// Not all markup has the parent as the wrapper, so explicitly specify
		// a target container.
		targetContainer: '.focal-point-wrapper'
	});

	lazyImage.init({
		addLoaders: true
	});

};

var addEventHandlers = function(){

	dom.document.off('.mobile');

	dom.document.on('pageshow.mobile', function(/*e, ui*/){

		$.mobile.ajaxEnabled = false;

		processPageLoad();

	});

	// this is to disable jquerymobile's annoying dom caching behavior
	// they try to do dom caching because they believe it will lead to smooth
	// 'page transitions'... the problem is, leaving 'old pages' lying around
	// in the dom can lead to tons of JS problems. For example, if you have an
	// #id (i.e. #mainNav) in your page (which should be unique by nature) and then you load
	// another page which also has the same #id (#mainNav), then your dom is now
	// in a broken and unreliable state, as it now has two elements with an id of #mainNav
	$('div').on('pagehide', function(event/*, ui*/){
		var page = jQuery(event.target);
		page.remove();
	});

	dom.document.on('pagebeforecreate.mobile', function(e){

		// turn off annoying jQueryMobile 'styling' of form controls
		// by styling, these guys wrap stuff in all sorts of divs and duplicate your
		// class names, which was breaking a lot of the js written on the store platform
		// (which is now being pulled into the mobile site, to support a mobile store experience)

		$('input,textarea,select,button', e.target).attr('data-role', 'none');

	});

	window.onload = window.onpageshow = function () {

		// this is necessary to hide the spinner when clicking the
		// back button in mobile safari
		hideLoader();

	};

	dom.document.on('vclick.mobile', '#home', function(){

		$.mobile.changePage('/?load=html&page=' + globals.homePageId + '&' + globals.previewMode);

	});

	dom.document.on('vclick.mobile', '#back', function(e){

		e.preventDefault();
		window.history.go(parseInt($(this).attr('data-steps')));

	});

};

var updateQueryStringParam = function(uri, key, value) {

	var re = new RegExp('([?|&])' + key + '=.*?(&|$)', 'i');

	var separator = uri.indexOf('?') !== -1 ? '&' : '?';

	if (uri.match(re)) {
		return uri.replace(re, '$1' + key + '=' + value + '$2');
	}

	else {
		return uri + separator + key + '=' + value;
	}

};

var init = function(){

	console.log( 'mobile init' );

	mobileMenu.init();

	addEventHandlers();

};

module.exports = {
	init: init,
	getUrlParam: getUrlParam,
	setPageId: setPageId,
	getPageId: getPageId,
	updateQueryStringParam: updateQueryStringParam,
	showLoader: showLoader,
	hideLoader: hideLoader
};
